import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Hyper = styled.h1`
  text-align: left;
  font-size: 7.5rem;
  font-family: helvetica;
  font-style: italic;
  color: white;
  -webkit-text-stroke: 1px blue;
  a {
    text-decoration: none;
    color: white;
    -webkit-text-stroke: 1px blue;
  }
  :hover #what {
    fill: blue;
  }
`
const About = styled.div`
  font-size: .8rem;
  font-family: Helvetica;
  margin-top: 1rem;
  color: mediumslateblue;
  a {
    color: inherit;
    text-transform: lowercase;
    // font-style: italic;
    letter-spacing: .02rem;
  }
`
const SecondPage = () => (
  <Layout>
    <SEO title="About this site" />
    <Hyper>about</Hyper>
    <About>
      <p>
      NARUS_NEWS is an interpersonal news platform that prioritizes candidness (minimal editing) as a means of lowering pressure and higher-ing outputs. Basically made for three people and the rest is voyeurism.
      <br></br><br></br>
      Well u know if I start disclosing too much I'll have to make this thing secret but<br></br>
      there's something kind of fun about a normal website right ? I'm kind of into that from the start. Anyway interpersonal news is no different from international news in that it's so specific that it's expansively generic, so maybe regardless I won't have to worry. The secret's safe with the nature of things.
      </p>
      <Link to="/">Go back to the homepage</Link>
    </About>
  </Layout>
)

export default SecondPage
